import { Component, ReactNode } from 'react';
import { BannerMenu } from '../components/BannerMenu';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export class ElopmentPhotos extends Component {
    private getPhotos(): any[] {
        return require
            .context('../photos/elopement/', false, /\.(png|jpe?g|svg)$/)
            .keys()
            .map(
                require.context(
                    '../photos/elopement/',
                    false,
                    /\.(png|jpe?g|svg)$/
                )
            );
    }

    render(): ReactNode {
        const images = this.getPhotos();

        return (
            <div>
                <BannerMenu />
                <div className="photos-page">
                    <h3 className="section-header">Elopement Photos</h3>
                    {images.map((val, i) => {
                        return (
                            <div key={i} className="img-container">
                                <LazyLoadImage key={i} alt="" src={val} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
