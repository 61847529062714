import { Component, ReactNode } from 'react';
import { ReactComponent as Logo } from '../../icons/menu-outline.svg';

export class SideMenu extends Component<{}, { isMenuVisible: boolean }> {
    constructor(props: any) {
        super(props);

        this.state = {
            isMenuVisible: false,
        };

        this.onMenuToggle = this.onMenuToggle.bind(this);

        window.addEventListener('touchstart', (event: any) => {
            if (event.changedTouches.length) {
                this.scrollStart = event.changedTouches[0].clientY;
            }
        });

        window.addEventListener('touchmove', (event: any) => {
            if (event.changedTouches.length) {
                if (this.scrollStart < event.changedTouches[0].clientY) {
                    document
                        .getElementById('toggle')
                        ?.classList.remove('tucked');
                } else {
                    document.getElementById('toggle')?.classList.add('tucked');
                }
            }
        });
    }

    scrollStart: number = -1;

    onMenuToggle(event: any) {
        event.preventDefault();

        this.setState({ isMenuVisible: !this.state.isMenuVisible });
    }

    render(): ReactNode {
        return (
            <div id="side-menu">
                <div onClick={this.onMenuToggle} id="toggle">
                    <Logo />
                </div>
                <div
                    id="shadow"
                    className={this.state.isMenuVisible ? '' : 'hidden'}
                    onClick={this.onMenuToggle}
                ></div>
                <div
                    id="fly-out"
                    className={this.state.isMenuVisible ? '' : 'hidden'}
                >
                    <div id="contents">
                        <ol>
                            <li
                                className={
                                    window.location.pathname === '/'
                                        ? 'this'
                                        : ''
                                }
                            >
                                <a href="/">HOME</a>
                            </li>
                            <li
                                className={
                                    window.location.pathname ===
                                    '/elopement_photos'
                                        ? 'this'
                                        : ''
                                }
                            >
                                <a href="/elopement_photos">ELOPMENT PHOTOS</a>
                            </li>
                            <li
                                className={
                                    window.location.pathname ===
                                    '/wedding_photos'
                                        ? 'this'
                                        : ''
                                }
                            >
                                <a href="/wedding_photos">WEDDING PHOTOS</a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}
