import { ReactElement, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { MobileContext } from './context/deviceContext';
import { ElopmentPhotos } from './pages/ElopementPhotos';
import { Landing as DesktopLanding } from './pages/desktop/Landing';
import { Landing as MobileLanding } from './pages/mobile/Landing';
import './styles/desktop/Main.scss';
import './styles/mobile/Main.scss';
import { WeddingPhotos } from './pages/WeddingPhotos';

export function App() {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [mobileContext, setMobileContext] = useState(width <= 768);

    function handleWindowSizeChange() {
        const innerWidth = window.innerWidth;
        setWidth(innerWidth);
        setMobileContext(innerWidth < 768);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return (
        <MobileContext.Provider value={mobileContext}>
            {mobileContext ? mobileApp() : desktopApp()}
        </MobileContext.Provider>
    );
}

function desktopApp(): ReactElement {
    return (
        <div id="desktop-app">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<DesktopLanding />} />
                    <Route
                        path="/elopement_photos"
                        element={<ElopmentPhotos />}
                    />
                    <Route path="/wedding_photos" element={<WeddingPhotos />} />
                    <Route
                        path="*"
                        element={
                            <div>
                                <h1>404 Not Found</h1>
                                <h3>
                                    Looks like you got lost! Let's go back{' '}
                                    <a href="/">home</a>.
                                </h3>
                                <img src="https://media3.giphy.com/media/QY7iot3kiXMlXSyexF/giphy.gif" />
                            </div>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

function mobileApp(): ReactElement {
    return (
        <div id="mobile-app">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MobileLanding />} />
                    <Route
                        path="/elopement_photos"
                        element={<ElopmentPhotos />}
                    />
                    <Route path="/wedding_photos" element={<WeddingPhotos />} />
                    <Route
                        path="*"
                        element={
                            <div>
                                <h1>404 Not Found</h1>
                                <h3>
                                    Looks like you got lost! Let's go back{' '}
                                    <a href="/">home</a>.
                                </h3>
                                <img src="https://media3.giphy.com/media/QY7iot3kiXMlXSyexF/giphy.gif" />
                            </div>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}
