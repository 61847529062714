import { Component, ReactNode } from 'react';
import { BannerMenu } from '../../components/BannerMenu';
import bannerImage from '../../photos/banner2.jpg';

export class Landing extends Component {
    render(): ReactNode {
        return (
            <div id="mobile-landing-page">
                <BannerMenu />
                <div>
                    <img src={bannerImage} alt="banner" />
                </div>
            </div>
        );
    }
}
