import { Component, ReactNode } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BannerMenu } from '../components/BannerMenu';

export class WeddingPhotos extends Component<{}, { images: any[] }> {
    constructor(props: any) {
        super(props);

        this.state = {
            images: [],
        };

        this.handleDirSelection = this.handleDirSelection.bind(this);
    }

    private gettingReadyImages = require
        .context('../photos/wedding/gettingReady', false, /\.(png|jpe?g|svg)$/)
        .keys()
        .map(
            require.context(
                '../photos/wedding/gettingReady',
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
    private arrivalImages = require
        .context('../photos/wedding/arrival', false, /\.(png|jpe?g|svg)$/)
        .keys()
        .map(
            require.context(
                '../photos/wedding/arrival',
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
    private familyPhotosImages = require
        .context('../photos/wedding/familyPhotos', false, /\.(png|jpe?g|svg)$/)
        .keys()
        .map(
            require.context(
                '../photos/wedding/familyPhotos',
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
    private ceremonyImages = require
        .context('../photos/wedding/ceremony', false, /\.(png|jpe?g|svg)$/)
        .keys()
        .map(
            require.context(
                '../photos/wedding/ceremony',
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
    private cocktailHourImages = require
        .context('../photos/wedding/cocktailHour', false, /\.(png|jpe?g|svg)$/)
        .keys()
        .map(
            require.context(
                '../photos/wedding/cocktailHour',
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
    private dinnerImages = require
        .context('../photos/wedding/dinner', false, /\.(png|jpe?g|svg)$/)
        .keys()
        .map(
            require.context(
                '../photos/wedding/dinner',
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
    private partyImages = require
        .context('../photos/wedding/party', false, /\.(png|jpe?g|svg)$/)
        .keys()
        .map(
            require.context(
                '../photos/wedding/party',
                false,
                /\.(png|jpe?g|svg)$/
            )
        );

    private handleDirSelection(event: React.MouseEvent<HTMLLIElement>) {
        let images: any[] = [];

        const requestedDir = event.currentTarget.getAttribute('data-dir');
        if (requestedDir === 'gettingReady') {
            images = this.gettingReadyImages;
        } else if (requestedDir === 'familyPhotos') {
            images = this.familyPhotosImages;
        } else if (requestedDir === 'arrival') {
            images = this.arrivalImages;
        } else if (requestedDir === 'ceremony') {
            images = this.ceremonyImages;
        } else if (requestedDir === 'cocktailHour') {
            images = this.cocktailHourImages;
        } else if (requestedDir === 'dinner') {
            images = this.dinnerImages;
        } else if (requestedDir === 'party') {
            images = this.partyImages;
        }

        this.setState({
            images,
        });

        // Toggle selected
        var elems = document.querySelectorAll('.selected');
        elems.forEach((e) => e.classList.remove('selected'));
        event.currentTarget.classList.add('selected');
    }

    render(): ReactNode {
        return (
            <div>
                <BannerMenu />
                <div className="photos-page">
                    <h3 className="section-header">Wedding Photos</h3>
                    <div className="dir-selection">
                        <ol>
                            <li
                                onClick={this.handleDirSelection}
                                data-dir="gettingReady"
                            >
                                Getting Ready
                            </li>
                            <li
                                data-dir="familyPhotos"
                                onClick={this.handleDirSelection}
                            >
                                Family Photos
                            </li>
                            <li
                                data-dir="arrival"
                                onClick={this.handleDirSelection}
                            >
                                Arrival
                            </li>
                            <li
                                data-dir="ceremony"
                                onClick={this.handleDirSelection}
                            >
                                Ceremony
                            </li>
                            <li
                                data-dir="cocktailHour"
                                onClick={this.handleDirSelection}
                            >
                                Cocktail Hour
                            </li>
                            <li
                                data-dir="dinner"
                                onClick={this.handleDirSelection}
                            >
                                Dinner
                            </li>
                            <li
                                data-dir="party"
                                onClick={this.handleDirSelection}
                            >
                                Party
                            </li>
                        </ol>
                    </div>
                    {this.state.images.map((val, i) => {
                        return (
                            <div key={i} className="img-container">
                                <LazyLoadImage key={i} alt="" src={val} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
